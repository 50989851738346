<template>
  <div>
    <template v-if='value'>
      <div style='display: flex; align-items: center'>
        <div style='flex:1'>
          <v-text-field
            :prepend-inner-icon='icons.mdiMapMarker'
            :value='value.full'
            :readonly='true'
            :clearable='true'
            outlined
            dense
            @click:clear='onClear'>
          </v-text-field>
        </div>

        <div style='width:110px; margin-left: 15px'>
          <v-text-field
            outlined
            dense
            placeholder='Unit, PO box' v-model='value.details'></v-text-field>
        </div>
      </div>
    </template>

    <v-text-field
      :prepend-inner-icon='icons.mdiMapMarker'
      outlined
      dense
      hide-details='auto'
      v-show='value === null'
      :id='id'
      ref='autocomplete'
      v-model='autocompleteText'
      :label='placeholder'
      :rules='[validationRule]'
      @blur='onBlur'>
    </v-text-field>
  </div>
</template>

<style>
.pac-target-input::placeholder {
  color: red !important;
  visibility: hidden !important;
}
</style>

<script>
import { mdiMapMarker } from '@mdi/js'

export default {
  props: {
    id: {
      default: 'autocomplete',
    },
    placeholder: {
      default: 'Search by address',
    },
    required: {
      default: false,
    },
    value: {
      default: null,
    },
  },

  computed: {
    validationRule() {
      return (v) => {
        if (this.required && !v) {
          return 'Field is required'
        }

        return true
      }
    },
  },

  methods: {
    onBlur() {
      setTimeout(() => {
        this.onPlaceChanged()
      }, 250)
    },

    onClear() {
      this.autocompleteText = ''
      this.onPlaceChanged()
    },

    onPlaceChanged() {
      if (this.autocompleteText === null || this.autocompleteText === '') {
        this.isInvalid = false
        this.$emit('input', null)

      } else {
        let place = this.autocomplete.getPlace()

        if (!place || !place.geometry) {
          this.isInvalid = true
          this.$emit('input', null)
        } else {
          this.isInvalid = false
          this.$emit('input', this.formatResult(place))
        }
      }
    },

    formatResult(place) {
      let returnData = {}

      let matches = {
        street_number: ['street_number', 'long_name'],
        route: ['route', 'long_name'],
        locality: ['city', 'long_name'],
        administrative_area_level_1: ['state', 'short_name'],
        postal_code: ['zip_code', 'long_name'],
        country: ['country', 'long_name'],
      }

      _.forEach(place.address_components, (component) => {

        let componentType = component.types[0]

        if (Array.isArray(matches[componentType])) {
          returnData[matches[componentType][0]] = component[matches[componentType][1]]
        }
      })

      returnData['street'] = returnData.route ? (returnData.street_number ? returnData.street_number + ' ' : '') + returnData.route : null
      returnData['full'] = place.formatted_address
      returnData['latitude'] = place.geometry.location.lat()
      returnData['longitude'] = place.geometry.location.lng()

      return returnData
    },
  },

  mounted() {
    let callback = () => {
      this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(this.id),{
        types: ['address'],
      })
      this.autocomplete.addListener('place_changed', this.onPlaceChanged)

      this.$nextTick(() => {
        document.getElementById('autocomplete').setAttribute('placeholder', '');
      })

    }

    if (window.google) {
      callback()
    } else {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCElcwQlyUSeGbKRt6O1XyNlQEijzYJTFQ&libraries=places'
      script.onload = callback
      document.body.appendChild(script)
    }
  },

  data() {
    return {
      autocomplete: null,
      autocompleteText: null,
      isInvalid: false,
      icons: {
        mdiMapMarker,
      },
    }
  },
}
</script>
